import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { isEmpty, map, path, prop } from 'ramda';

import { getSchema } from '../common/schema';

import { SEO } from '../components/core/seo';
import { getInternalURL } from '../common/link';
import { BodyArticles } from '../components/layout/body/articles';

export const query = graphql`
  query BlogTopicQuery($id: String!, $locale: String!) {
    settings: site {
      siteMetadata {
        siteUrl
      }
    }
    site: datoCmsSite {
      ...SiteInformation
    }
    page: datoCmsBlogTopic(
      id: { eq: $id }
    ) {
      model {
        ...PageModel
      }
      meta {
        ...PageMeta
      }
      seo {
        ...PageSEO
      }
      locale
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      name
    }
    articles: allDatoCmsBlogArticle(
      sort: {fields: meta___firstPublishedAt, order: DESC},
      filter: {slug: {ne: null}, topics: {elemMatch: {id: {eq: $id}}}}
    ) {
      nodes {
        model {
          ...PageModel
        }
        meta {
          ...PageMeta
        }
        slug
        title
        thumbnail{
          ...SquareThumbnail
        }
        authors {
          ...Author
        }
        topics {
          ...Topic
        }
      }
    }
    topics: allDatoCmsBlogTopic(
      filter: {locale: {eq: $locale}}
    ) {
      nodes {
        model {
          ...PageModel
        }
        slug
        name
      }
    }
  }
`;

const BlogTopic = ({ data, pageContext }: any) => {
  const settings:any = path(['settings', 'siteMetadata'], data);
  const articles:any = path(['articles', 'nodes'], data);
  const topics:any = path(['topics', 'nodes'], data);
  const page = prop('page', data);
  const site = prop('site', data);

  if (!page || !settings || !site) return null;

  const schema = getSchema({ settings, site, page, pageContext });
  const pageURL = settings.siteUrl + getInternalURL(page);

  return (
    <Fragment>
      <SEO
        tags={page.seoMetaTags}
        favicon={site.faviconMetaTags}
        siteURL={settings.siteUrl}
        schema={schema}
        locale={pageContext.locale}
        defaultLocale={pageContext.defaultLocale}
        alternateLocales={pageContext.alternateLocales}
      />

      <BodyArticles articles={articles} topics={topics} heading={page.name} />
      
    </Fragment>
  );
};

export default BlogTopic;