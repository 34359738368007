import styled from "styled-components";
import { getGridAtSize, getMediaQuery, getUnitAsPixels, GRID, Size } from "../../../theme/layout";
import { getTypography, Type } from "../../../theme/typography";
import { LinkInternal } from "../../core/link";

export const Container = styled.div`
  ${GRID}
`

export const Content = styled.ul`
  grid-column: 1 / span 8;

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 12;
    display: grid;
    ${getGridAtSize(Size.MEDIUM,12)}
  }

  ${getMediaQuery(Size.LARGE)}{
    grid-column: 4 / span 9;
    ${getGridAtSize(Size.LARGE,9)}
  }
`;

export const Item = styled.li`

  ${getMediaQuery(Size.MEDIUM)}{  
    margin-bottom: ${getUnitAsPixels({multiplier:1})};
    &:nth-child(odd){
      grid-column: 1 / span 6;
    }

    &:nth-child(even){
      grid-column: 7 / span 6;
    }
  }

  ${getMediaQuery(Size.LARGE)}{  
    &:nth-child(odd){
      grid-column: 1 / span 4;
    }

    &:nth-child(even){
      grid-column: 6 / span 4;
    }
  }
`;

export const Link = styled(LinkInternal)`
  ${getTypography(Type.LARGE_HEADING)}
  color: ${({ theme }) => theme.primary.hex};
  text-decoration: none;
  transition: color 0.3s;

  &:hover{
    color: ${({theme}) => theme.secondary.hex};
  }
`