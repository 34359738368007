
import React from 'react';
import { useDictionary } from '../../../../context/dictionary';
import { ArticleList } from '../../../article/articleList';
import { ArticleListVariant } from '../../../article/articleList/articleList.types';
import { TopicList } from '../../../topic/topicList';
import { ArticlesContainer, Container, Content, TopicsContainer } from './articles.styles';
import { BodyArticlesProps } from './articles.types';

export const BodyArticles = ({articles,topics,heading}:BodyArticlesProps) => {
  const labelBlogMoreTopics = useDictionary("labelBlogMoreTopics");

  return (
    <Container>
      <Content>
        <ArticlesContainer>
          <ArticleList variant={ArticleListVariant.FEATURE} items={articles} heading={heading} />
        </ArticlesContainer>
        {topics && <TopicsContainer>
          <TopicList items={topics} heading={labelBlogMoreTopics} />
        </TopicsContainer>}
      </Content>
    </Container>
  )
};