import styled from "styled-components";
import { pixelate } from "../../../../theme/helpers";
import { CONTAINER, getHeaderAsNumber, getLayout, getMediaQuery, getVerticalSpaceAsNumber, getVerticalSpaceAsPixels, Layout, Size, VerticalSpaceVariant } from "../../../../theme/layout";

export const Container = styled.div`
  ${CONTAINER}
  padding-top: ${pixelate(getHeaderAsNumber(Size.SMALL) + getVerticalSpaceAsNumber(Size.SMALL,VerticalSpaceVariant.LARGE))};

  ${getMediaQuery(Size.MEDIUM)}{
    padding-top: ${pixelate(getHeaderAsNumber(Size.MEDIUM) + getVerticalSpaceAsNumber(Size.MEDIUM,VerticalSpaceVariant.LARGE))};
  }

  ${getMediaQuery(Size.LARGE)}{
    padding-top: ${pixelate(getHeaderAsNumber(Size.LARGE) + getVerticalSpaceAsNumber(Size.LARGE,VerticalSpaceVariant.LARGE))};
  }

  ${getMediaQuery(Size.XLARGE)}{
    padding-top: ${pixelate(getHeaderAsNumber(Size.XLARGE) + getVerticalSpaceAsNumber(Size.XLARGE,VerticalSpaceVariant.LARGE))};
  }

  ${getMediaQuery(Size.MAX)}{
    padding-top: ${pixelate(getHeaderAsNumber(Size.MAX) + getVerticalSpaceAsNumber(Size.MAX,VerticalSpaceVariant.LARGE))};
  }
`;

export const Content = styled.div`
  ${getLayout(Layout.BLOCK)}
`

export const ArticlesContainer = styled.div`
  padding-bottom: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE)};

  ${getMediaQuery(Size.MEDIUM)}{
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.LARGE)}{
    padding-bottom: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.XLARGE)}{
    padding-bottom: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.MAX)}{
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.LARGE)};
  }
`

export const TopicsContainer = styled.div`
  padding-bottom: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE)};

  ${getMediaQuery(Size.MEDIUM)}{
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.LARGE)}{
    padding-bottom: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.XLARGE)}{
    padding-bottom: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.MAX)}{
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.LARGE)};
  }
`