import React from "react"
import { BlockHeader } from "../../block/header"

import { Container, Content, Item, Link } from "./topicList.styles"
import { TopicListProps } from "./topicList.types"

export const TopicList = ({ items, heading, subheading }:TopicListProps) => {
  return (
    <Container>
      <BlockHeader heading={heading} subheading={subheading} />
      <Content>
        {items.map((item, index) => <Item key={index}><Link page={item} label={item.name} /></Item>)}
      </Content>
    </Container>
  )
}